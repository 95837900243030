<template>
  <vs-sidebar click-not-close position-right parent="#app" class="message-sidebar" spacer v-model="localActiveSidebar">
    <div class="mt-6 flex items-center justify-between px-6" slot="header">
        <h2 class="text-white"> Ver detalles de notificación </h2>
        <feather-icon icon="XIcon" @click.stop="localActiveSidebar = false" class="cursor-pointer"></feather-icon>
    </div>
    
    <VuePerfectScrollbar class="scroll-area--data-list-add-new px-5 pb-10">
      <vs-input label="Titulo" v-model="message.title" :readonly="true" class="w-full"></vs-input>
      
      <vs-textarea label="Cuerpo" v-model="message.body" :readonly="true" class="w-full mt-5"></vs-textarea>

      <vs-input label="Creada" :value="convertTimestamp( message.created_at )" :readonly="true" class="w-full"></vs-input>

      <div class="mt-5 flex justify-center">
        <img :src="message.image || image_placeholder" class="w-1/4 rounded-sm">
      </div>

      <div class="mt-5">
        <h5>Resultados de envío</h5>
        <label for="">Enviados:</label> <span> {{ message.message_data.success }} </span> <br/>
        <label for="">Fallidos:</label> <span> {{ message.message_data.failure }} </span> <br/>
      </div>
    </VuePerfectScrollbar>
            
    <div class="flex justify-end p-6" slot="footer">
      <button type="submit" class="vs-component vs-button vs-button-info vs-button-filled" @click="localActiveSidebar = false">
        Cerrar
      </button>
    </div>
  </vs-sidebar>
</template>

<script lang="ts">
import Vue                 from 'vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default Vue.extend({
    props: {
      sidebar: {  required: true, type: Boolean, },
      id: { required: true, type: String },
    },

    data() {
      return {
        image_placeholder : require('@/assets/images/elements/image_placeholder.png'),
      }
    },

    watch : {
      id(val) {
        if( val === '' ) return;
        this.getMessage(val);
      }
    },

    methods: {
      async getMessage(message_id) {
        await this.$store.dispatch('messages/get', message_id);
      },
      // convert timestamp YYYYMMDDHHMMSS to DD/MM/YYYY HH:MM:SS
      convertTimestamp(timestamp) {
        if( !timestamp || timestamp === 0 ) return '';
        const year = timestamp.substring(0, 4);
        const months = timestamp.substring(4, 6);
        const days = timestamp.substring(6, 8);
        const hours = timestamp.substring(8, 10);
        const minutes = timestamp.substring(10, 12);
        const seconds = timestamp.substring(12, 14);

        return year + '-' + months + '-' + days + ' ' + hours + ':' + minutes + ':' + seconds;
      },
    },

    computed: {
      localActiveSidebar: {
        get: function() { return this.sidebar; },
        set: function(val) {                
         this.$emit( 'sidebar', val );
         this.$store.dispatch( 'messages/getAll' );
        }
      },

      message() {
        return this.$store.state.messages.message;
      }
    },

    components: {
        VuePerfectScrollbar,
    }
});
</script>

<style lang="scss" scoped>
.message-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }
    
  ::v-deep .vs-sidebar {
    position: fixed;
    z-index: 52010;
    width: 450px;
    max-width: 90vw;
    .vs-sidebar--header {
      background: #841910;
      color: #FFF;
    }
  }
}

.scroll-area--data-list-add-new {
  height: 79vh !important;
}

textarea {
  height: 80px;
  line-height: 150%;
  resize:vertical;
  box-sizing: padding-box;
  font-size: 1rem;
  border-radius: 6px;
  box-shadow: 2px 2px 8px rgba(black, .3);
  border: 2;
}
</style>
