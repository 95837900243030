<template>
  <div>
    <vx-card>
      <h1 class="text-info"> Lista de notificaciones enviadas</h1>

      <!-- loading and delete icon -->
      <div class="flex justify-end">
        <feather-icon v-if="selected.length == 0" icon="RefreshCwIcon" class="cursor-pointer" :class="{ 'animate-spin': loading }" @click="getData()"></feather-icon>
        <feather-icon v-else icon="TrashIcon" class="cursor-pointer" @click="del()"></feather-icon>
      </div>

      <vs-table search multiple :max-items="maxItems" pagination v-model="selected" :data="messages" :noDataText=" $t( 'Datos no disponibles' ) ">

        <template slot="thead">
          <vs-th>Titulo</vs-th>
          <vs-th>Cuerpo</vs-th>
          <vs-th>Estado</vs-th>
          <vs-th></vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" v-bind:key="indextr" v-for="(tr, indextr) in data">

            <!-- title -->
            <vs-td :data="data[indextr].title">
              <span class="text-info"> {{ data[indextr].title }} </span>
            </vs-td>

            <!-- body -->
            <vs-td :data="data[indextr].body">
              <span class="text-info"> {{ data[indextr].body }} </span>
            </vs-td>

            <!-- status -->
            <vs-td :data="data[indextr].status">
              <span class="text-info"> {{ this.$t( data[indextr].status ) }} </span>
            </vs-td>

            <!-- options -->
            <vs-td :data="data[indextr].id">
              <feather-icon icon="EyeIcon" class="cursor-pointer" @click.stop="viewMessage( data[indextr].id )"></feather-icon>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>

    <view-sidebar :id="message_id" :sidebar="view_message" @sidebar="view_message = false"></view-sidebar>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import ViewSidebar from './View.vue';

export default Vue.extend({
  data() {
    return {
      loading : false,
      maxItems : Number(process.env.VUE_APP_ITEMS_PER_PAGE) || 10,
      selected : [],
      message_id : '',
      view_message : false,
    }
  },

  created() {
    this.getData();
  },

  methods : {
    async getData() {
      this.loading = true;
      await this.$store.dispatch('messages/getAll');
      this.loading = false;
    },
    viewMessage( id ) {
      this.message_id = id;
      this.view_message = true;
    },
    async del() {
      if( this.selected.length === this.messages.length ) {        
        this.loading = true;
        await this.$store.dispatch('messages/deleteMassive');
        this.loading = false;
        this.selected = [];

      } else {
        for await(let message of this.selected) {
          this.loading = true;
          await this.$store.dispatch('messages/delete', message.id);
          this.loading = false;
        }
        this.selected = [];

      }


    }
  },

  computed : {
    messages() {
      return this.$store.state.messages.messages;
    },
  },

  components : {
    ViewSidebar,
  }
})
</script>
